<div class="container background-accent">
  <div class="icon-container">
    <mat-icon inline>network_check</mat-icon>
  </div>

  <h1 i18n="@@error-0.headline">
    Sie haben keine Verbindung mit dem Internet.
  </h1>

  <p i18n="@@error-0.info">
    Sie sehen diese Seite weil wir keine Verbindung mit dem Server aufnehmen konnten.<br>
    <br>
    Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind und laden sie diese Seite erneut.
  </p>

</div>
