import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, first} from 'rxjs/operators';

@Component({
  selector: 'qua-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading: any;

  constructor(private router: Router) {
  }

  ngOnInit(): void {

    this.router.events.pipe(
      filter(evt => evt instanceof NavigationStart)
    ).subscribe(() => {
      this.loading = true;
    });

    this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ).subscribe(() => {
      this.loading = false;
    });

    // Kill the global loader after the first navigation ends.
    this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd),
      first(),
    ).subscribe((evt) => {
      const elem = document.getElementById('loader');

      // Kill the root loader and show a background fade animation.
      elem.classList.add('fade-out');
      elem.classList.remove('background-accent');

      setTimeout(() => {
        elem.remove();
      }, 300);
    });
  }
}
