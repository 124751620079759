import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  templateUrl: './error-0.component.html',
  styleUrls: ['./error-0.component.scss']
})
export class Error0Component {

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }
}
