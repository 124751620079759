import {Injectable} from '@angular/core';

export interface OwnerData {
  url: string;
  name: string;
  impressum: string;
  dataprotection: string;
  agb: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private companyId: string;
  private eventId: string;
  private surveyId: string;
  private gatewayURL: any;

  constructor() {
    console.log('[AppService] started...');

    // @ts-ignore
    this.gatewayURL = window.__qua_gateway || 'http://localhost:8080';
  }

  prepareBackendUrl(url: string): string {
    return this.resolveURL(this.gatewayURL + url);
  }

  setActiveCompanyId(c: string): void {
    console.log('[AppService] company changed to', c);
    this.companyId = c;
  }

  getActiveCompanyId(): string {
    return this.companyId;
  }

  setActiveEventId(c: string): void {
    console.log('[AppService] event changed to', c);
    this.eventId = c;
  }

  getActiveEventId(): string {
    return this.eventId;
  }

  setActiveSurveyId(surveyId: string): void {
    console.log('[AppService] survey changed to', surveyId);
    this.surveyId = surveyId;
  }

  getActiveSurveyId(): string {
    return this.surveyId;
  }

  resolveURL(url: string): string {
    // Inject the active companyId
    const companyId = this.getActiveCompanyId();
    url = url.replace('{companyId}', companyId);

    // Inject the active eventId
    const eventId = this.getActiveEventId();
    url = url.replace('{eventId}', eventId);

    const surveyId = this.getActiveSurveyId();
    url = url.replace('{surveyId}', surveyId);

    return url;
  }

  getAppName(): string {
    return (window as any).__qua_app_name || 'qua';
  }

  getOwner(): OwnerData {
    return (window as any).__qua_owner || { name: '[qua]' };
  }
}
