import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {RouterModule} from '@angular/router';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {Error404Component} from './error-404.component';
import {Error403Component} from './error-403.component';
import {Error0Component} from './error-0.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSnackBarModule,
    MatIconModule,
  ],
  declarations: [
    Error403Component,
    Error404Component,
    Error0Component,
  ],
  exports: [
    Error403Component,
    Error0Component,
  ]
})
export class ErrorPagesModule {

}
