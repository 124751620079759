import {forwardRef, Inject, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppService} from '../services/app.service';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccessDeniedBarComponent} from '../components/errors/error403/access-denied-bar.component';
import {NotFoundBarComponent} from '../components/errors/error404/not-found-bar.component';
import {Router} from '@angular/router';
import {SessionExpiredBarComponent} from '../components/errors/error401/session-expired-bar.component';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingInterceptorService implements HttpInterceptor {

  constructor(private appService: AppService,
              private router: Router,
              private snackBarService: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        // Automagically logout the user if a 401 is thrown, but ignore the login page...
        if (err.status === 401 && !request.url.endsWith('login')) {
          this.router.navigate([this.appService.getActiveCompanyId(), 'logout']);
          this.snackBarService.openFromComponent(SessionExpiredBarComponent, {
            duration: 1500,
          });
        } else if (err.status === 403) {
          this.snackBarService.openFromComponent(AccessDeniedBarComponent, {
            duration: 1500,
          });
        } else if (err.status === 404) {
          this.snackBarService.openFromComponent(NotFoundBarComponent, {
            duration: 1500,
          });
        }  else if (err.status === 0) {
          this.router.navigateByUrl('errors/0', {
            skipLocationChange: true,
          });
        }

        throw err;
      }),
    );
  }
}
