import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppService} from '../services/app.service';

@Injectable({
  providedIn: 'root',
})
export class BackendRouteInterceptor implements HttpInterceptor {

  constructor(private appService: AppService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newUrl: string = request.url;

    // HINT: Skip all request which already have a full URL.
    if (!(request.url.startsWith('http') || request.url.startsWith('ws'))) {
      newUrl = this.appService.prepareBackendUrl(newUrl);
    }

    newUrl = this.appService.resolveURL(newUrl);

    request = request.clone({
      url: newUrl,
    });

    return next.handle(request);
  }
}
