import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {TitleSetterComponent} from './title-setter.component';

const c = [
  TitleSetterComponent,
];

@NgModule({
  imports: [
  ],
  declarations: c,
  exports: c,
})
export class TitleSetterModule {}
