import { BrowserModule } from '@angular/platform-browser';
import {Inject, Injectable, LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {WithCredentialsInterceptorService} from './shared/interceptors/with-credentials-interceptor.service';
import {ErrorPagesModule} from './shared/pages/error-pages/error-pages.module';
import {BackendRouteInterceptor} from './shared/interceptors/backend-route-interceptor.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter} from '@angular/material/core';
import {ErrorHandlingInterceptorService} from './shared/interceptors/error-handling-interceptor.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AccessDeniedBarComponent} from './shared/components/errors/error403/access-denied-bar.component';
import {NotFoundBarComponent} from './shared/components/errors/error404/not-found-bar.component';
import {SessionExpiredBarComponent} from './shared/components/errors/error401/session-expired-bar.component';
import {TitleSetterComponent} from './shared/components/title-setter/title-setter.component';
import {TitleSetterModule} from './shared/components/title-setter/title-setter.module';
import {ProfilePageComponent} from './shared/pages/profile/profile-page.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getGermanPaginatorIntl} from './paginator-intl';

@Injectable()
export class CustomNativeDateAdapter extends NativeDateAdapter {

  private options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  parse(value: any): Date | null {
    const parts = value.match(/(\d+)/g);

    if (parts.length < 3) {
      return null;
    }

    const y = parts[2];
    const m = parts[1] - 1;
    const d = parts[0];

    return new Date(y, m, d);
  }

  format(date: Date, displayFormat: object): string {
    return date.toLocaleString(this.locale, { ...displayFormat, ...this.options });
  }
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    MatSnackBarModule,

    AppRoutingModule,
    ErrorPagesModule,
    TitleSetterModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
  ],
  declarations: [
    AppComponent,

    AccessDeniedBarComponent,
    NotFoundBarComponent,
    SessionExpiredBarComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    {
      provide: DateAdapter,
      useClass: CustomNativeDateAdapter,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendRouteInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
