import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {Error403Component} from './shared/pages/error-pages/error-403.component';
import {Error404Component} from './shared/pages/error-pages/error-404.component';
import {Error0Component} from './shared/pages/error-pages/error-0.component';

const routes: Routes = [{
  path: 'admin',
  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
}, {
  path: 'survey',
  loadChildren: () => import('./responder/responder.module').then(m => m.ResponderModule)
}, {
  path: 'results',
  loadChildren: () => import('./responder/results.module').then(m => m.ResultsModule)
}, {
  path: 'clusters',
  loadChildren: () => import('./responder/clusters.module').then(m => m.ClustersModule)
}, {
  path: 'errors/403',
  component: Error403Component,
}, {
  path: 'errors/0',
  component: Error0Component,
}, {
  path: ':companyId',
  loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: '/admin/login',
}, {
    path: '**',
    component: Error404Component,
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: 'eager',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
