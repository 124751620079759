import {Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'qua-title-setter',
  template: ''
})
export class TitleSetterComponent implements OnInit {
  @Input() parts: string;

  constructor(private title: Title,
              private appService: AppService) {
  }

  ngOnInit(): void {
    const name = this.appService.getAppName();
    const allParts = [name, ...this.parts.split(';')].reverse();
    this.title.setTitle(allParts.join(' | '));
  }
}
