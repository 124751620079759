import {MatPaginatorIntl} from '@angular/material/paginator';

export function getGermanPaginatorIntl(): MatPaginatorIntl {
  const intl = new MatPaginatorIntl();

  intl.firstPageLabel = 'Erste Seite';
  intl.lastPageLabel = 'Letzte Seite';
  intl.nextPageLabel = 'Nächste Seite';
  intl.previousPageLabel = 'Letzte Seite';

  intl.itemsPerPageLabel = 'Elemente pro Seite';
  intl.getRangeLabel = (page, pageSize, length) => {
    const start = ((page) * pageSize) + 1;
    let end = ((page + 1) * pageSize);
    if (end > length) {
      end = length;
    }
    return `${start} - ${end} von ${length}`;
  };

  return intl;
}
